import { type Injector } from '@angular/core';
import { type PlantDetailEntity } from '@sds/api/administration/models';
import { PlantResolver } from '@sds/shared/resolvers';
import { BaseWidget } from '@sds/widget-gridster';
import { first } from 'rxjs/operators';

export class IncomeWidget extends BaseWidget<{ plant: PlantDetailEntity; title: string }> {
  static readonly id = 'income';
  static readonly publicFriendly = false;
  private readonly resolver: PlantResolver = this.injector.get(PlantResolver);

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    return new IncomeWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    super(injector, IncomeWidget.id, IncomeWidget.publicFriendly, metaLinkId, x, y, rows, cols, data);
    this.resolveComponent(async () => {
      const module = await import('./income.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string }) {
    this.resolver
      .resolveByMetaId(data.plantId)
      .pipe(first())
      .subscribe(plant => {
        this.setData({ plant, title: data.title });
      });
  }
}
